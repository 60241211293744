<script setup lang="ts">
import { BaseRichText, BaseTextarea } from '#components'

const props = withDefaults(
  defineProps<{
    name: string
    label?: string
    richText?: boolean
    placeholder?: string
    isSubmitting?: boolean
    cols?: number | string
    rows?: number | string
    maxlength?: number
  }>(),
  {
    label: undefined,
    placeholder: undefined,
    isSubmitting: false,
    cols: 12,
    rows: undefined,
    maxlength: undefined,
  },
)

const componentToRender = computed(() =>
  props.richText ? BaseRichText : BaseTextarea,
)
</script>

<template>
  <div :class="`col-span-${cols}`">
    <Field v-slot="{ field, errorMessage }" :name="name">
      <component
        :is="componentToRender"
        :label="label"
        :placeholder="placeholder"
        :error="errorMessage"
        :disabled="isSubmitting"
        :rows="rows"
        :maxlength="maxlength"
        v-bind="field"
      />
    </Field>
  </div>
</template>
